import * as React from "react"
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../components/layoutComponents/layout"
import Seo from "../components/seo"
import {contact} from "./../styles/sections/contact.module.scss"


export default function ContactPage() {
  const intl = useIntl();


  return (
    <Layout>
    <Seo title="Home" />
    <section className="hero">
      <h1>{intl.formatMessage({ id: "Contact" })}</h1>
      <h2>Antonín Šimkanin</h2>
      <ul className={contact}>
        <li>antonin.simkanin@tntsoft.cz</li>
        <li>+420 725 583 461</li>
      </ul>
    </section>
  </Layout>
  )
}